@import './typography';

@mixin container {
  @media (min-width: 1200px) {
    display: flex;
    margin-left: 90px; // 90px is w.r.t. aside width.
  }
}

@mixin content-text {
  padding: 6rem 0 5rem 0;
  position: relative; // for serial-no-large
  h3 {
    @include heading-text;
  }
  & > p {
    @include paragraph-below-heading;
  }
}

@mixin before-background {
  position: relative;
  &::before {
    background: #f8f8f8;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    width: 65%;
  }
}

@mixin content {
  @include before-background;

  @media (min-width: 1200px) {
    width: 60%;
  }
}

@mixin modal {
  .modal {
    background: $primary-color;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    max-height: 75%;
    max-width: 80%;
    outline: none;
    overflow: auto;
    padding: 3rem;
    text-align: center;
    @media (min-width: 768px) {
      padding: 4.5rem;
    }
  }

  .modal__image {
    max-height: 40rem;
    max-width: 100%;
    object-fit: cover;
    @media (min-width: 768px) {
      min-height: 30rem;
      max-height: 50rem;
    }
    @media (min-width: 992px) {
      max-height: 55rem;
    }
  }

  .modal__txt {
    margin-top: 2rem;
    text-align: center;
    span {
      color: $font-color-light;
      font-size: 1rem;
      letter-spacing: 0.4rem;
      text-transform: uppercase;
    }
  }
}
