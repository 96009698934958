/* overwriting react-modal styles (start) */
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 200ms ease-in-out;
  z-index: 499; // since header component's sideDrawer has z-index of 399.
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--befor-close {
  opacity: 0;
}
/* overwriting react-modal styles (end) */

// for closing modal.
.modal-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 25px;
  width: 25px;
  padding: 7px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @media (min-width: 768px) {
    top: 16px;
    right: 16px;
  }
}
