@import './variables';

@mixin btn-text {
  border: none;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

@mixin heading-text {
  color: $black;
  font-size: 2.6rem;
  line-height: 3.4rem;
  letter-spacing: 0.2rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
  position: relative;
  &::before {
    background: $secondary-color;
    content: '';
    position: absolute;
    top: -2rem;
    left: 0;
    height: 2px;
    width: 3rem;
  }
}

@mixin paragraph-below-heading {
  color: $font-color-light;
  font-size: 1rem;
  font-weight: bold;
  line-height: 2.2rem;
  letter-spacing: 0.1rem;
  max-width: 450px;
  padding: 1rem 0;
  text-transform: uppercase;
}

@mixin paragraph {
  font-size: 1.2rem;
  line-height: 2.4rem;
}

@mixin form-input-text {
  border: $border;
  color: $color;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  width: 100%;
  &::placeholder {
    font-weight: bold;
  }
  &:focus {
    outline: 0;
  }
}
