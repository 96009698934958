/*******************
Box Sizing
*******************/
html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* 62.5% is chosen since 1rem is 16px; therefore, 16px*0.625=10px. Now according to this calculation, 2.2rem=22px. */ }

*,
*::before,
*::after {
  box-sizing: inherit; }

/****************************
Generic Margins and Paddings
****************************/
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
p,
pre,
blockquote,
figure,
hr {
  margin: 0;
  padding: 0; }

body {
  font-family: 'Mukta', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #26262a;
  color: #333333; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

button {
  font-family: 'Mukta', sans-serif;
  cursor: pointer; }
  button:focus {
    outline: 0; }

.contact-details_contactDetails__2MDEd {
  display: none; }
  @media (min-width: 1200px) {
    .contact-details_contactDetails__2MDEd {
      background: #34343a;
      flex: 4 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 90px;
      padding: 0 8rem;
      position: relative;
      font-size: 1rem;
      letter-spacing: 0.3rem;
      text-transform: uppercase; }
      .contact-details_contactDetails__2MDEd::before {
        content: '';
        background: #f9bf26;
        position: absolute;
        right: -1.5rem;
        height: 1.5px;
        width: 3rem; }
      .contact-details_contactDetails__2MDEd span {
        color: #767676;
        font-weight: bold;
        margin-left: 3rem;
        position: relative; }
        .contact-details_contactDetails__2MDEd span::before {
          content: '\2015';
          color: #f9bf26;
          font-size: 1rem;
          position: absolute;
          top: 0;
          left: -2rem; }
      .contact-details_contactDetails__2MDEd a {
        color: #ffffff;
        margin: 0 0.5rem;
        padding: 0.5rem;
        text-decoration: none;
        white-space: nowrap; } }

.share-btn_shareBtn__rvGYf {
  background-color: #2d2d32;
  border: none;
  padding: 3.2rem 2.5rem;
  position: relative; }
  .share-btn_shareBtn__rvGYf span {
    color: #767676;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    position: relative;
    text-transform: uppercase; }
    .share-btn_shareBtn__rvGYf span::before {
      content: '';
      background: #f9bf26;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -6px;
      height: 1px; }

.share-btn_social__34Alt {
  background-color: #26262a;
  position: fixed;
  top: 90px;
  left: 0;
  width: 85%;
  max-width: 350px;
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.share-btn_active__1Fo79 {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }

.share-btn_social__closeBtn__y9aP3 {
  background-color: #2d2d32;
  border: none;
  color: #767676;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  position: relative;
  text-align: right;
  text-transform: uppercase;
  padding: 2rem 4rem;
  width: 100%; }
  .share-btn_social__closeBtn__y9aP3::before {
    content: '\2190';
    color: #f9bf26;
    font-size: 1rem;
    position: absolute;
    top: 1.9rem;
    right: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px); }

.share-btn_social__links__63HIp {
  padding: 4.5rem; }
  .share-btn_social__links__63HIp a {
    color: #767676;
    display: block;
    font-size: 1rem;
    letter-spacing: 0.25rem;
    margin-bottom: 0.4rem;
    padding: 0.5rem 0;
    position: relative;
    text-decoration: none; }
    .share-btn_social__links__63HIp a:hover {
      color: #f9bf26; }
      .share-btn_social__links__63HIp a:hover::before {
        content: '\2015';
        color: #f9bf26;
        font-size: 1rem;
        position: absolute;
        top: 0.5rem;
        left: -2rem; }

.social-icons_social__aTuGQ {
  background: #2b2b2f;
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex; }
  .social-icons_social__aTuGQ a {
    width: 4rem;
    height: 4rem;
    padding: 1.2rem; }
    .social-icons_social__aTuGQ a:hover {
      background: #34343a;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out; }
    .social-icons_social__aTuGQ a img {
      object-fit: contain;
      width: 100%;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out; }
  .social-icons_social__vertical__3kLCY {
    flex-direction: column;
    justify-content: center; }
    .social-icons_social__vertical__3kLCY > :not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05); }
  .social-icons_social__horizontal__36Xgb {
    flex-direction: row;
    justify-content: center; }
    .social-icons_social__horizontal__36Xgb > :not(:last-child) {
      border-right: 1px solid rgba(255, 255, 255, 0.05); }

.side-nav_sideNav__1QNI8 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 90px);
  overflow-y: auto; }

.side-nav_sideNav__links__JaSPE {
  padding: 8rem 3rem 10rem 4rem; }
  .side-nav_sideNav__links__JaSPE a {
    color: #767676;
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding: 1rem;
    text-decoration: none; }
    .side-nav_sideNav__links__JaSPE a:hover {
      background: #34343a;
      color: #f9bf26; }

.side-nav_sideNav__social__2fSkO {
  background: #2d2d32;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem; }
  .side-nav_sideNav__social__2fSkO span {
    display: none; }
    @media (min-width: 768px) {
      .side-nav_sideNav__social__2fSkO span {
        display: inline-block;
        color: #767676;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0.4rem;
        margin-right: 2rem; } }

.header_headerContainer__VWFil {
  height: 90px; }

.header_header__1e2AG {
  background: #2d2d32;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 90px;
  width: 100%;
  z-index: 199; }
  .header_header__1e2AG::before {
    content: '';
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05); }
    @media (min-width: 1200px) {
      .header_header__1e2AG::before {
        left: 90px; } }

.header_header__companyLogo__1r8BI {
  flex: 2 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 90px;
  margin: 0 1rem; }
  @media (min-width: 1200px) {
    .header_header__companyLogo__1r8BI {
      margin: 0 1rem 0 9rem; } }
  .header_header__companyLogo__1r8BI img {
    cursor: pointer;
    object-fit: contain;
    max-height: 90px;
    max-width: 150px;
    padding: 4px 10px; }

.header_header__menuIcon__xPQtk {
  cursor: pointer;
  object-fit: contain;
  margin: 0 1rem;
  padding: 15px;
  width: 60px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .header_header__menuIcon__xPQtk {
      margin: 0 5rem; } }

.header_header__closeBtn__3AyQK {
  cursor: pointer;
  object-fit: contain;
  margin: 0 1rem;
  padding: 20px;
  width: 60px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .header_header__closeBtn__3AyQK {
      margin: 0 5rem; } }

.header_sideDrawer__3Wy5r {
  background: #26262a;
  position: fixed;
  top: 90px;
  right: 0;
  height: 100%;
  width: 70%;
  max-width: 450px;
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  z-index: 399; }

.header_sideDrawer_open__3hBYB {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.header_backdrop__1APlg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 90px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 299; }
  @media (min-width: 1200px) {
    .header_backdrop__1APlg {
      left: 90px; } }

@-webkit-keyframes aside_translate__1x0ir {
  from {
    -webkit-transform: translateY(100px);
            transform: translateY(100px); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes aside_translate__1x0ir {
  from {
    -webkit-transform: translateY(100px);
            transform: translateY(100px); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.aside_aside__1BQwT {
  display: none; }
  @media (min-width: 1200px) {
    .aside_aside__1BQwT {
      border-right: 1px solid rgba(255, 255, 255, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      background: #2d2d32;
      width: 90px;
      position: fixed;
      top: 90px;
      left: 0;
      bottom: 0;
      z-index: 199; }
      .aside_aside__1BQwT > h1,
      .aside_aside__1BQwT > span {
        color: #767676;
        font-size: 1rem;
        font-weight: bold;
        letter-spacing: 0.4rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        white-space: nowrap; } }

.aside_aside__heading__usF-V {
  position: absolute;
  top: 156px;
  width: 350px;
  height: 4rem;
  overflow: hidden; }
  .aside_aside__heading__usF-V span {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-animation: aside_translate__1x0ir 0.5s;
            animation: aside_translate__1x0ir 0.5s;
    height: 100%;
    padding: 1rem 3rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden; }
  .aside_aside__heading__usF-V::before {
    content: '';
    background: #34343a;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 150px;
    z-index: -9; }

.aside_aside__share__u7yqo {
  margin-top: 1rem;
  padding: 3.5rem 2.5rem;
  position: relative; }
  .aside_aside__share__u7yqo::before {
    content: '\2015';
    color: #f9bf26;
    font-size: 1rem;
    position: absolute;
    left: 0; }
  .aside_aside__share__u7yqo::after {
    content: '\007C';
    color: #f9bf26;
    font-size: 1rem;
    position: absolute;
    left: 0.5rem; }

.ad_sideDrawer__1tb0w {
  -webkit-filter: drop-shadow(1px 0px 7px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(1px 0px 7px rgba(0, 0, 0, 0.5));
  position: fixed;
  top: 44.5%;
  right: 0;
  width: 70%;
  max-width: 350px;
  -webkit-transform: translateX(calc(100% - 4.3rem));
          transform: translateX(calc(100% - 4.3rem));
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  z-index: 199; }

.ad_sideDrawer_open__3dtM9 {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.ad_details__2IbMq {
  font-size: 1.2rem;
  line-height: 2.4rem;
  background: #f9bf26;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 4.4rem 100%, 4.4rem 4rem, 0 4rem, 0 0);
          clip-path: polygon(100% 0, 100% 100%, 4.4rem 100%, 4.4rem 4rem, 0 4rem, 0 0);
  cursor: pointer;
  display: flex;
  align-items: flex-start; }
  .ad_details__vertical__23c3h {
    font-size: 1.8rem;
    font-weight: bold;
    padding: 1rem;
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: upright;
            text-orientation: upright; }
  .ad_details__txt__zNSPC {
    padding: 1rem; }
    .ad_details__txt__zNSPC ul {
      list-style-type: square;
      list-style-position: inside; }
    .ad_details__txt__zNSPC span,
    .ad_details__txt__zNSPC p {
      font-size: 1rem; }

.txt-container_txtContainer__2NSeX {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 29; }
  @media (min-width: 768px) {
    .txt-container_txtContainer__2NSeX {
      padding-left: 10%; } }
  .txt-container_txtContainer__2NSeX > div {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%; }

.txt-container_txtContainer__grid__1Z1we {
  width: 100%;
  max-width: 55rem;
  display: grid;
  grid-template-areas: 'heading' 'description' 'link';
  padding: 0 5rem; }
  @media (min-width: 768px) {
    .txt-container_txtContainer__grid__1Z1we {
      grid-template-areas: 'date heading' 'date description' 'date link';
      padding: 0; } }
  .txt-container_txtContainer__gridItem1__2n3Gq {
    display: none;
    grid-area: date; }
    @media (min-width: 768px) {
      .txt-container_txtContainer__gridItem1__2n3Gq {
        display: block; }
        .txt-container_txtContainer__gridItem1__2n3Gq span {
          color: #b6b6b6;
          display: inline-block;
          letter-spacing: 0.1rem;
          font-size: 1.4rem;
          font-weight: bold;
          text-transform: uppercase;
          -webkit-transform: rotate(-90deg) translateX(-5.1rem);
                  transform: rotate(-90deg) translateX(-5.1rem); } }
  .txt-container_txtContainer__gridItem2__LPCf0 {
    color: #ffffff;
    grid-area: heading;
    font-family: 'Oswald', sans-serif;
    font-size: 3.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    position: relative; }
    .txt-container_txtContainer__gridItem2__LPCf0::before {
      content: '';
      background: #f9bf26;
      position: absolute;
      top: -6rem;
      width: 5rem;
      height: 2px; }
    .txt-container_txtContainer__gridItem2__LPCf0 span {
      color: #f9bf26; }
    @media (min-width: 992px) {
      .txt-container_txtContainer__gridItem2__LPCf0 {
        font-size: 5.5rem; } }
  .txt-container_txtContainer__gridItem3__1BVod {
    color: #b6b6b6;
    font-size: 1.2rem;
    grid-area: description;
    margin-bottom: 2rem; }
    @media (min-width: 992px) {
      .txt-container_txtContainer__gridItem3__1BVod {
        font-size: 1.4rem; } }
  .txt-container_txtContainer__gridItem4__VtI9X {
    grid-area: link;
    color: #ffffff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    height: 3rem;
    width: 12rem;
    text-decoration: none;
    text-transform: uppercase;
    position: relative; }
    .txt-container_txtContainer__gridItem4__VtI9X span::before {
      content: '\27F6';
      color: #f9bf26;
      position: absolute;
      right: 0;
      font-size: 1rem;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }
    .txt-container_txtContainer__gridItem4__VtI9X:hover span::before {
      -webkit-transform: translateX(0.5rem);
              transform: translateX(0.5rem);
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }

.img-container_imgContainer__2mI8f {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9; }
  @media (min-width: 992px) {
    .img-container_imgContainer__2mI8f {
      left: 30%; } }
  .img-container_imgContainer__2mI8f img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.nav-btns_nav__37tQ4 {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199; }
  @media (min-width: 1200px) {
    .nav-btns_nav__37tQ4 {
      left: 90px; } }
  .nav-btns_nav__37tQ4 button {
    border: none;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    height: 6rem;
    position: relative; }
  .nav-btns_nav__37tQ4 > div {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    max-width: 30rem; }

.nav-btns_nav__expLink__3iHiU {
  background: #f9bf26;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  height: 6rem;
  width: 40%;
  max-width: 18rem;
  text-decoration: none;
  text-transform: uppercase;
  position: relative; }
  @media (min-width: 768px) {
    .nav-btns_nav__expLink__3iHiU span {
      margin-left: -2rem;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .nav-btns_nav__expLink__3iHiU span::before {
        content: '\27F6';
        color: #ffffff;
        position: absolute;
        right: 2rem;
        font-size: 1rem;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s; }
    .nav-btns_nav__expLink__3iHiU:hover span {
      margin-left: -3rem;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .nav-btns_nav__expLink__3iHiU:hover span::before {
        -webkit-transform: translateX(0.5rem);
                transform: translateX(0.5rem);
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s; } }

.nav-btns_nav__prevBtn__3Kaaz {
  background: #2d2d32;
  color: #767676;
  width: 50%;
  max-width: 15rem; }
  .nav-btns_nav__prevBtn__3Kaaz span {
    margin-right: -2rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .nav-btns_nav__prevBtn__3Kaaz span::before {
      content: '\2039';
      color: #ffffff;
      position: absolute;
      top: 2rem;
      left: 3rem;
      font-size: 1.2rem;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }
  @media (min-width: 768px) {
    .nav-btns_nav__prevBtn__3Kaaz span::before {
      left: 4.5rem; }
    .nav-btns_nav__prevBtn__3Kaaz:hover {
      background: #f9bf26;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .nav-btns_nav__prevBtn__3Kaaz:hover span {
        color: #ffffff;
        left: 4.5rem;
        margin-right: -2.5rem;
        -webkit-transition: all 0.2s;
        transition: all 0.2s; }
        .nav-btns_nav__prevBtn__3Kaaz:hover span::before {
          -webkit-transform: translateX(-0.5rem);
                  transform: translateX(-0.5rem);
          -webkit-transition: -webkit-transform 0.2s;
          transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
          transition: transform 0.2s, -webkit-transform 0.2s; } }

.nav-btns_nav__nextBtn__2O32L {
  background: #2d2d32;
  color: #767676;
  width: 50%;
  max-width: 15rem; }
  .nav-btns_nav__nextBtn__2O32L span {
    margin-left: -2rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .nav-btns_nav__nextBtn__2O32L span::before {
      content: '\203A';
      color: #ffffff;
      position: absolute;
      top: 2rem;
      right: 3rem;
      font-size: 1.2rem;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }
  @media (min-width: 768px) {
    .nav-btns_nav__nextBtn__2O32L span::before {
      right: 4.5rem; }
    .nav-btns_nav__nextBtn__2O32L:hover {
      background: #f9bf26;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      .nav-btns_nav__nextBtn__2O32L:hover span {
        color: #ffffff;
        right: 4.5rem;
        margin-left: -2.5rem;
        -webkit-transition: all 0.2s;
        transition: all 0.2s; }
        .nav-btns_nav__nextBtn__2O32L:hover span::before {
          -webkit-transform: translateX(0.5rem);
                  transform: translateX(0.5rem);
          -webkit-transition: -webkit-transform 0.2s;
          transition: -webkit-transform 0.2s;
          transition: transform 0.2s;
          transition: transform 0.2s, -webkit-transform 0.2s; } }

.carousel_carousel__3FD86 {
  color: #767676;
  overflow: hidden;
  position: relative;
  height: calc(100vh - 90px); }

.carousel_carousel__background__Jip7a {
  display: none; }
  @media (min-width: 992px) {
    .carousel_carousel__background__Jip7a {
      background: #34343a;
      display: block;
      position: absolute;
      top: 15%;
      left: 10%;
      bottom: 15%;
      width: 60%; }
      .carousel_carousel__background__Jip7a::before {
        content: '';
        border-top: 1px solid #45454d;
        border-left: 1px solid #45454d;
        position: absolute;
        top: -3rem;
        left: -3rem;
        height: 5rem;
        width: 5rem; }
      .carousel_carousel__background__Jip7a::after {
        content: '';
        border-bottom: 1px solid #45454d;
        border-left: 1px solid #45454d;
        position: absolute;
        bottom: -3rem;
        left: -3rem;
        height: 5rem;
        width: 5rem; } }

.carousel_carousel__overlay__2Y3Ry {
  background: #2d2d32;
  opacity: 0.5;
  border-left: 1px solid #45454d;
  border-right: 1px solid #45454d;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19; }
  @media (min-width: 992px) {
    .carousel_carousel__overlay__2Y3Ry {
      left: 30%; } }
  .carousel_carousel__overlay__2Y3Ry::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20%;
    bottom: 0;
    border-left: 1px solid #45454d;
    border-right: 1px solid #45454d;
    width: 20%; }
  .carousel_carousel__overlay__2Y3Ry::after {
    content: '';
    position: absolute;
    top: 0;
    right: 20%;
    bottom: 0;
    border-left: 1px solid #45454d;
    border-right: 1px solid #45454d;
    width: 20%; }

.rotate-screen_rotate__110Xo {
  display: none; }
  @media (max-height: 575px) and (orientation: landscape) {
    .rotate-screen_rotate__110Xo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh; }
      .rotate-screen_rotate__110Xo img {
        -webkit-filter: invert(1) brightness(70%);
                filter: invert(1) brightness(70%);
        height: 10rem;
        width: 10rem; }
      .rotate-screen_rotate__110Xo span {
        color: #b6b6b6;
        font-size: 1.4rem;
        margin-top: 2rem; } }

.home-pg_home__17tq8 {
  min-height: 100vh; }
  @media (max-height: 575px) and (orientation: landscape) {
    .home-pg_home__17tq8 {
      display: none; } }
  @media (min-width: 1200px) {
    .home-pg_home__content__3FAV8 {
      margin-left: 90px; } }

.get-in-touch-link_getInTouch__-cb4s {
  border: 1px solid #34343a;
  color: #767676;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  padding: 1.2rem 2rem;
  text-decoration: none;
  text-transform: uppercase; }
  .get-in-touch-link_getInTouch__-cb4s img {
    object-fit: contain;
    margin-left: 2rem;
    width: 1rem; }
  .get-in-touch-link_getInTouch__-cb4s:hover {
    background: #f9bf26;
    color: #ffffff; }
    .get-in-touch-link_getInTouch__-cb4s:hover img {
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1); }
  .get-in-touch-link_getInTouch_white__2IxR4 {
    color: #b6b6b6; }
    .get-in-touch-link_getInTouch_white__2IxR4 img {
      -webkit-filter: brightness(25%) invert(1);
              filter: brightness(25%) invert(1); }

.footer_footer__g9SO2 {
  color: #767676;
  padding-top: 5rem; }
  .footer_footer__g9SO2 > :first-child {
    margin: 0 auto;
    width: 92%; }
  @media (min-width: 1200px) {
    .footer_footer__g9SO2 {
      margin: 0 auto 0 90px;
      min-width: 900px;
      max-width: 58%; }
      .footer_footer__g9SO2 > :first-child {
        display: flex;
        align-items: flex-start;
        max-width: 824px;
        padding: 12rem 0 17rem; } }
  @media (min-width: 1200px) {
    .footer_footer_full__1siRX {
      max-width: 100%; }
      .footer_footer_full__1siRX > :first-child {
        max-width: 1254px; } }

@media (min-width: 1200px) {
  .footer_footer__details__gHSVa {
    min-width: 400px;
    max-width: 33.33%; } }

.footer_footer__details__gHSVa img {
  object-fit: contain;
  max-height: 90px;
  max-width: 150px; }

.footer_footer__details__gHSVa p {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2.4rem;
  padding: 5rem 5rem 1rem 0;
  position: relative; }
  .footer_footer__details__gHSVa p::before {
    background: #f9bf26;
    content: '';
    position: absolute;
    bottom: -4rem;
    left: 0;
    width: 5rem;
    height: 1px; }

.footer_footer__contact__3T-c_ {
  text-transform: uppercase; }
  @media (min-width: 1200px) {
    .footer_footer__contact__3T-c_ {
      padding: 0 1.5rem; } }
  .footer_footer__contact__3T-c_ > span {
    display: block;
    font-weight: bold;
    font-size: 1.1rem;
    letter-spacing: 0.2rem;
    margin-bottom: 1.5rem;
    padding-top: 1rem;
    position: relative; }
    .footer_footer__contact__3T-c_ > span::before {
      background: #767676;
      content: '';
      position: absolute;
      bottom: 0.4rem;
      left: 9rem;
      right: 0;
      height: 1px; }
  .footer_footer__contact__3T-c_ > div {
    display: inline-block;
    margin: 2rem 0 5rem 0; }

.footer_footer__list__38JY5 {
  list-style: none;
  margin-top: 4rem; }
  .footer_footer__list__38JY5 li {
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.2rem;
    padding-bottom: 1rem;
    text-transform: uppercase; }
    .footer_footer__list__38JY5 li span {
      display: inline-block;
      min-width: 9rem;
      padding-right: 3rem; }
    .footer_footer__list__38JY5 li span:nth-of-type(2) {
      color: #b6b6b6; }
    .footer_footer__list__38JY5 li a {
      color: #b6b6b6;
      font-size: 1.2rem;
      text-decoration: none; }

.footer_footer__copywrite__1YxB7 {
  background: #2d2d32;
  color: #767676;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  width: 96%; }
  .footer_footer__copywrite__1YxB7 > div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: 4rem 4%; }
  .footer_footer__copywrite__1YxB7 button {
    background: #34343a;
    border: none;
    color: #ffffff;
    font-family: 'Mukta', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    height: 9rem;
    width: 100%; }
    .footer_footer__copywrite__1YxB7 button:hover {
      color: #f9bf26; }
  @media (min-width: 768px) {
    .footer_footer__copywrite__1YxB7 {
      display: flex;
      align-items: center;
      width: 100%; }
      .footer_footer__copywrite__1YxB7 > div {
        flex: 2 1;
        flex-flow: row wrap;
        padding: 2.3rem 4%; }
      .footer_footer__copywrite__1YxB7 button {
        width: 16rem; } }
  @media (min-width: 1200px) {
    .footer_footer__copywrite__1YxB7 > div {
      max-width: calc(824px - 16rem);
      margin: 0 auto;
      padding: 2.3rem 0; } }
  @media (min-width: 1200px) {
    .footer_footer__copywrite_full__1_jC3 {
      width: 93%; }
      .footer_footer__copywrite_full__1_jC3 > div {
        max-width: calc(1254px - 16rem);
        padding: 2.3rem 4%; } }

.footer_footer__love__3Wrio {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  margin-top: 1.5rem; }
  @media (min-width: 768px) {
    .footer_footer__love__3Wrio {
      margin-top: 0;
      margin-left: 1.5rem; } }
  .footer_footer__love__3Wrio img {
    width: 1.2rem; }
  .footer_footer__love__3Wrio a {
    color: #b6b6b6;
    font-weight: bold;
    text-decoration: none; }

.footer_footer__credits__1Rwue {
  color: #5d5d5d;
  font-size: 1rem;
  font-weight: normal;
  margin-top: 2rem;
  text-transform: none;
  text-transform: initial;
  width: 100%; }
  @media (min-width: 768px) {
    .footer_footer__credits__1Rwue {
      margin-top: 1rem;
      margin-left: 1.3rem; } }
  .footer_footer__credits__1Rwue a {
    color: inherit;
    font-weight: bold;
    text-decoration: none; }

.banner_banner__15qES {
  background: center/cover no-repeat var(--banner-bg);
  height: 40rem;
  position: relative; }
  .banner_banner__15qES::before {
    content: '';
    border-top: 1px solid #b6b6b6;
    border-right: 1px solid #b6b6b6;
    position: absolute;
    top: 5rem;
    right: 5rem;
    height: 7rem;
    width: 7rem;
    z-index: 19; }
  @media (min-width: 1200px) {
    .banner_banner__15qES {
      height: calc(100vh - 90px);
      order: 2;
      position: -webkit-sticky;
      position: sticky;
      top: 90px;
      width: 40%; } }
  .banner_banner__overlay__1-5FN {
    background: #2d2d32;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9; }

.banner_banner__content__PlnXX {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40%;
  left: 4%;
  right: 4%;
  z-index: 19; }
  @media (min-width: 1200px) {
    .banner_banner__content__PlnXX {
      top: unset;
      left: 5rem;
      right: 5rem;
      bottom: 12rem;
      align-items: flex-end;
      text-align: right; } }
  .banner_banner__content__PlnXX h2 {
    color: #ffffff;
    font-family: 'Oswald', sans-serif;
    font-size: 4.5rem;
    line-height: 5rem;
    padding-bottom: 2rem; }
    .banner_banner__content__PlnXX h2::before {
      background: #f9bf26;
      content: '';
      position: absolute;
      top: -4rem;
      left: 0;
      height: 2px;
      width: 4rem; }
    @media (min-width: 1200px) {
      .banner_banner__content__PlnXX h2::before {
        left: unset;
        right: 0; } }
  .banner_banner__content__PlnXX p {
    color: #b6b6b6;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.4rem;
    max-width: 450px;
    padding-bottom: 1rem; }

.section_section__1EEnY {
  background: #ffffff;
  padding-top: 5rem;
  padding-bottom: 2rem; }
  .section_section__1EEnY > div {
    margin: 0 auto;
    width: 92%;
    max-width: 824px; }

.serial-no-large_number__1tPW0 {
  color: #e9e9e9;
  font-size: 12.4rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.7;
  z-index: 9; }
  .serial-no-large_number__1tPW0 span {
    position: relative; }
    .serial-no-large_number__1tPW0 span::before {
      background: #ffffff;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 50%;
      width: 100%; }

.content_content__1e9E0 {
  position: relative; }
  .content_content__1e9E0::before {
    background: #f8f8f8;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    width: 65%; }
  @media (min-width: 1200px) {
    .content_content__1e9E0 {
      width: 60%; } }

.content_story__201_q {
  border-bottom: 1px solid #f6f6f6;
  padding: 6rem 0 5rem 0;
  position: relative; }
  .content_story__201_q h3 {
    color: #000000;
    font-size: 2.6rem;
    line-height: 3.4rem;
    letter-spacing: 0.2rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    position: relative; }
    .content_story__201_q h3::before {
      background: #f9bf26;
      content: '';
      position: absolute;
      top: -2rem;
      left: 0;
      height: 2px;
      width: 3rem; }
  .content_story__201_q > p {
    color: #b6b6b6;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    max-width: 450px;
    padding: 1rem 0;
    text-transform: uppercase; }
  .content_story__201_q img {
    object-fit: cover;
    width: 100%; }
  .content_story__txt__1ZUqg {
    padding-top: 5rem; }
    .content_story__txt__1ZUqg h4 {
      color: #000000;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 3.4rem;
      padding-bottom: 2rem; }
    .content_story__txt__1ZUqg div p {
      font-size: 1.2rem;
      line-height: 2.4rem;
      padding-bottom: 1rem; }
    @media (min-width: 1200px) {
      .content_story__txt__1ZUqg {
        display: flex;
        justify-content: space-between; }
        .content_story__txt__1ZUqg h4 {
          width: 30%;
          max-width: 15rem; }
        .content_story__txt__1ZUqg div {
          width: 60%; } }

.content_stats__2Y3g9 {
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  justify-content: space-around;
  padding: 2rem 0; }
  .content_stats__2Y3g9 > div {
    max-width: 15rem; }
    .content_stats__2Y3g9 > div span {
      color: #000000;
      font-size: 3rem;
      font-weight: bold; }
    .content_stats__2Y3g9 > div h5 {
      color: #767676;
      font-size: 1rem;
      letter-spacing: 0.2rem;
      margin-top: 1.2rem;
      padding-left: 2rem;
      position: relative;
      text-transform: uppercase; }
      .content_stats__2Y3g9 > div h5::before {
        background: #f9bf26;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 1px; }

.content_team__1EhCv {
  padding: 6rem 0 5rem 0;
  position: relative; }
  .content_team__1EhCv h3 {
    color: #000000;
    font-size: 2.6rem;
    line-height: 3.4rem;
    letter-spacing: 0.2rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    position: relative; }
    .content_team__1EhCv h3::before {
      background: #f9bf26;
      content: '';
      position: absolute;
      top: -2rem;
      left: 0;
      height: 2px;
      width: 3rem; }
  .content_team__1EhCv > p {
    color: #b6b6b6;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    max-width: 450px;
    padding: 1rem 0;
    text-transform: uppercase; }
  .content_team__1EhCv ul {
    list-style-type: square;
    list-style-position: inside; }
    .content_team__1EhCv ul li {
      font-size: 1.2rem;
      line-height: 2.4rem; }

@media (min-width: 1200px) {
  .about-us-pg_about__3X0GH {
    display: flex;
    margin-left: 90px; } }

.grid-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  gap: 1rem;
  grid-auto-flow: dense;
  margin: 4% auto;
  width: 92%; }
  @media (min-width: 768px) {
    .grid-layout {
      grid-template-columns: repeat(auto-fill, minmax(20%, auto)); } }
  @media (min-width: 1200px) {
    .grid-layout {
      grid-gap: 0.5rem;
      gap: 0.5rem;
      margin: 0;
      width: 100%; } }

.grid-item {
  height: 100%;
  width: 100%;
  overflow: hidden; }
  .grid-item img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out; }
  .grid-item:hover {
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
    .grid-item:hover img {
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1); }

@media (min-width: 768px) {
  .square {
    grid-column-end: span 2;
    grid-row-end: span 2; } }

/* overwriting react-modal styles (start) */
.ReactModalPortal > div {
  opacity: 0; }

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  z-index: 499; }

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1; }

.ReactModalPortal .ReactModal__Overlay--befor-close {
  opacity: 0; }

/* overwriting react-modal styles (end) */
.modal-close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 25px;
  width: 25px;
  padding: 7px;
  cursor: pointer; }
  .modal-close-btn:focus {
    outline: none; }
  @media (min-width: 768px) {
    .modal-close-btn {
      top: 16px;
      right: 16px; } }

.content_content__1tGrj {
  width: 100%; }

.content_modal__2fOi4 {
  background: #2d2d32;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  max-height: 75%;
  max-width: 80%;
  outline: none;
  overflow: auto;
  padding: 3rem;
  text-align: center; }
  @media (min-width: 768px) {
    .content_modal__2fOi4 {
      padding: 4.5rem; } }

.content_modal__image__1L7wZ {
  max-height: 40rem;
  max-width: 100%;
  object-fit: cover; }
  @media (min-width: 768px) {
    .content_modal__image__1L7wZ {
      min-height: 30rem;
      max-height: 50rem; } }
  @media (min-width: 992px) {
    .content_modal__image__1L7wZ {
      max-height: 55rem; } }

.content_modal__txt__UZx1l {
  margin-top: 2rem;
  text-align: center; }
  .content_modal__txt__UZx1l span {
    color: #b6b6b6;
    font-size: 1rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase; }

@media (min-width: 1200px) {
  .portfolio-pg_portfolio__zefDT {
    display: flex;
    margin-left: 90px; } }

.content_content__zbxgr {
  width: 100%; }

.content_banner__1hNoJ {
  position: relative; }
  .content_banner__1hNoJ > div {
    position: relative; }
    .content_banner__1hNoJ > div::before {
      content: '';
      border-top: 1px solid #45454d;
      border-left: 1px solid #45454d;
      position: absolute;
      top: 3rem;
      left: 3rem;
      height: 5rem;
      width: 5rem; }
    .content_banner__1hNoJ > div::after {
      background: #34343a;
      content: '';
      position: absolute;
      top: 4rem;
      right: 0;
      bottom: 4rem;
      width: 35%;
      z-index: -9; }
  .content_banner__1hNoJ img {
    display: block;
    object-fit: cover;
    min-height: 300px;
    width: 100%; }

.content_banner__txtContainer__2snqc {
  padding: 9rem 0;
  margin: 0 auto;
  width: 92%;
  max-width: 1224px; }
  @media (min-width: 1200px) {
    .content_banner__txtContainer__2snqc {
      display: flex;
      justify-content: center; }
      .content_banner__txtContainer__2snqc > div {
        width: 33.33%;
        margin-left: 10rem; } }
  .content_banner__txtContainer__2snqc h2 {
    color: #ffffff;
    font-size: 3.2rem;
    line-height: 4.4rem;
    max-width: 230px; }
  .content_banner__txtContainer__2snqc p {
    color: #767676;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 2.4rem;
    padding-bottom: 1rem; }
    .content_banner__txtContainer__2snqc p::before {
      content: '';
      border-bottom: 1px solid #45454d;
      border-left: 1px solid #45454d;
      position: absolute;
      bottom: 4rem;
      left: 3rem;
      height: 5rem;
      width: 5rem; }
  .content_banner__txtContainer__2snqc button {
    background: inherit;
    border: 0;
    color: #ffffff;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    margin-top: 1rem;
    padding: 1rem 3rem 1rem 0;
    position: relative;
    text-transform: uppercase; }
    .content_banner__txtContainer__2snqc button::before {
      content: '\2192';
      position: absolute;
      top: 1rem;
      right: 0;
      -webkit-transform: rotate(0);
              transform: rotate(0);
      -webkit-transition: -webkit-transform 0.2s;
      transition: -webkit-transform 0.2s;
      transition: transform 0.2s;
      transition: transform 0.2s, -webkit-transform 0.2s; }
    .content_banner__txtContainer__2snqc button:hover {
      color: #f9bf26; }
      .content_banner__txtContainer__2snqc button:hover::before {
        color: #ffffff;
        content: '\2192';
        position: absolute;
        top: 1rem;
        right: 0;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transition: -webkit-transform 0.2s;
        transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
        transition: transform 0.2s, -webkit-transform 0.2s; }

.content_txtContainer__1fstL {
  background: #ffffff;
  padding: 10rem 0; }
  .content_txtContainer__1fstL > div {
    margin: 0 auto;
    width: 92%;
    max-width: 1254px; }
  @media (min-width: 1200px) {
    .content_txtContainer__1fstL > div {
      display: flex;
      justify-content: space-between; }
    .content_txtContainer__content__3_Cbq {
      width: 60%; } }

.content_details__NLE_i {
  border-bottom: 1px solid #f6f6f6; }
  @media (min-width: 1200px) {
    .content_details__NLE_i {
      border-bottom: 0;
      height: 100%;
      position: -webkit-sticky;
      position: sticky;
      top: calc(90px + 1rem);
      width: 35%; } }
  .content_details__NLE_i h3 {
    color: #000000;
    font-size: 2.2rem;
    line-height: 3.4rem;
    padding-bottom: 2rem; }
  .content_details__NLE_i p {
    color: #b6b6b6;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    max-width: 450px;
    padding: 1rem 0;
    text-transform: uppercase;
    max-width: 100%; }
  .content_details__NLE_i ul {
    background: #26262a;
    list-style: none;
    margin-top: 4rem;
    margin-bottom: 3rem;
    padding: 3rem 4.5rem; }
    .content_details__NLE_i ul li {
      color: #767676;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.2rem;
      padding: 1rem 0;
      text-transform: uppercase;
      word-break: break-all; }
      .content_details__NLE_i ul li span {
        color: #f9bf26; }

.content_info__1hAii {
  border-bottom: 1px solid #f6f6f6;
  padding-top: 5rem; }
  @media (min-width: 1200px) {
    .content_info__1hAii {
      padding-top: 0; } }
  .content_info__1hAii h3 {
    color: #000000;
    font-size: 2.2rem;
    line-height: 3.4rem;
    padding-bottom: 2rem; }
  .content_info__1hAii p {
    font-size: 1.2rem;
    line-height: 2.4rem;
    padding-bottom: 1rem; }

.content_gallery__1Mt-q {
  padding-top: 5rem; }
  .content_gallery__1Mt-q h3 {
    color: #000000;
    font-size: 2.2rem;
    line-height: 3.4rem;
    padding-bottom: 2rem; }
  .content_gallery__grid__2b_q1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem; }
  .content_gallery__gridItem__aXzF9 {
    max-height: 150px;
    overflow: hidden; }
    .content_gallery__gridItem__aXzF9 img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      -webkit-transition: all 0.4s ease-out;
      transition: all 0.4s ease-out; }
    .content_gallery__gridItem__aXzF9:hover img {
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1); }
    .content_gallery__gridItem_video__UjM-8 {
      background: #c6c6cb;
      border: 1px solid #f6f6f6;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: none;
      padding: 1rem; }
    .content_gallery__gridItem_image__35AZp {
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
  @media (min-width: 768px) {
    .content_gallery__grid__2b_q1 {
      grid-gap: 2rem;
      gap: 2rem; }
    .content_gallery__gridItem__aXzF9 {
      max-height: 200px; }
      .content_gallery__gridItem_video__UjM-8 {
        max-height: none;
        padding: 2rem; } }
  @media (min-width: 1200px) {
    .content_gallery__grid__2b_q1 {
      grid-gap: 1.5rem;
      gap: 1.5rem; } }

.content_modal__1dxVw {
  background: #2d2d32;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  max-height: 75%;
  max-width: 80%;
  outline: none;
  overflow: auto;
  padding: 3rem;
  text-align: center; }
  @media (min-width: 768px) {
    .content_modal__1dxVw {
      padding: 4.5rem; } }

.content_modal__image__1ouGB {
  max-height: 40rem;
  max-width: 100%;
  object-fit: cover; }
  @media (min-width: 768px) {
    .content_modal__image__1ouGB {
      min-height: 30rem;
      max-height: 50rem; } }
  @media (min-width: 992px) {
    .content_modal__image__1ouGB {
      max-height: 55rem; } }

.content_modal__txt__1XRuA {
  margin-top: 2rem;
  text-align: center; }
  .content_modal__txt__1XRuA span {
    color: #b6b6b6;
    font-size: 1rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase; }

.page-not-found_text__1o_qq,
.page-not-found_link__3Tl9F {
  color: #ffffff; }

@media (min-width: 1200px) {
  .project-details-pg_details__1RMQr {
    display: flex;
    margin-left: 90px; } }

.image-and-list_container__1i4wZ {
  padding-top: 2rem;
  padding-bottom: 5rem;
  position: relative; }
  .image-and-list_container__1i4wZ::before {
    background: #f9bf26;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 7rem; }
  .image-and-list_container__1i4wZ img {
    object-fit: cover;
    width: 100%; }

.image-and-list_container__list__mufgN {
  background: #26262a;
  list-style: none;
  padding: 4.5rem;
  margin: -1rem auto 0;
  max-width: 380px;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .image-and-list_container__list__mufgN {
      margin: 0;
      position: absolute;
      bottom: 0;
      right: 10rem;
      z-index: 9; } }
  .image-and-list_container__list__mufgN > :not(:last-child) {
    margin-bottom: 2rem; }
  .image-and-list_container__list__mufgN li {
    color: #b6b6b6;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    margin-left: 20px;
    text-indent: -23px;
    word-break: break-all; }
    .image-and-list_container__list__mufgN li strong {
      color: #767676; }
    .image-and-list_container__list__mufgN li span {
      color: #f9bf26;
      padding-left: 1rem; }
    .image-and-list_container__list__mufgN li a {
      color: #f9bf26;
      padding-left: 1rem;
      text-decoration: none; }

.order-project_orderProject__2B8te {
  background: #26262a;
  margin: 3rem 0 6rem;
  padding: 3rem 4rem;
  max-width: 824px; }
  .order-project_orderProject__2B8te > div h4 {
    color: #767676;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin-bottom: 3rem;
    padding-left: 5rem;
    text-transform: uppercase;
    position: relative; }
    .order-project_orderProject__2B8te > div h4::before {
      background: #f9bf26;
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 3rem;
      height: 1px; }
  @media (min-width: 768px) {
    .order-project_orderProject__2B8te {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4rem 0 0; }
      .order-project_orderProject__2B8te > div {
        background: #2d2d32;
        padding: 4rem;
        width: 60%; }
        .order-project_orderProject__2B8te > div h4 {
          margin-bottom: 0; } }

.content_content__sm75I {
  position: relative; }
  .content_content__sm75I::before {
    background: #f8f8f8;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    width: 65%; }
  @media (min-width: 1200px) {
    .content_content__sm75I {
      width: 60%; } }

.content_service__2MXZf {
  border-bottom: 1px solid #f6f6f6;
  padding: 6rem 0 5rem 0;
  position: relative; }
  .content_service__2MXZf h3 {
    color: #000000;
    font-size: 2.6rem;
    line-height: 3.4rem;
    letter-spacing: 0.2rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
    position: relative; }
    .content_service__2MXZf h3::before {
      background: #f9bf26;
      content: '';
      position: absolute;
      top: -2rem;
      left: 0;
      height: 2px;
      width: 3rem; }
  .content_service__2MXZf > p {
    color: #b6b6b6;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2.2rem;
    letter-spacing: 0.1rem;
    max-width: 450px;
    padding: 1rem 0;
    text-transform: uppercase; }
  .content_service__description__2yj3z {
    padding-top: 5rem; }
    .content_service__description__2yj3z p {
      font-size: 1.2rem;
      line-height: 2.4rem; }
    @media (min-width: 1200px) {
      .content_service__description__2yj3z {
        display: flex;
        align-items: center; } }

.content_service__price__1SAmV {
  display: flex;
  justify-content: flex-end; }
  .content_service__price__1SAmV > div {
    background: #26262a;
    color: #b6b6b6;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    margin-top: 3rem;
    padding: 1.5rem 3rem;
    text-transform: uppercase;
    white-space: nowrap; }
    .content_service__price__1SAmV > div span {
      color: #f9bf26;
      font-weight: normal;
      margin-right: 1.5rem;
      padding-right: 1.5rem;
      position: relative; }
      .content_service__price__1SAmV > div span::before {
        background: #767676;
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        width: 1px; }
  @media (min-width: 1200px) {
    .content_service__price__1SAmV {
      display: block;
      margin-left: 3rem; }
      .content_service__price__1SAmV > div {
        margin-top: 0; } }

@media (min-width: 1200px) {
  .services-pg_services__2R2NT {
    display: flex;
    margin-left: 90px; } }

.form_form__aJV9d {
  padding-top: 2rem; }
  .form_form__aJV9d input {
    border: 1px solid #eeeeee;
    color: #666666;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    padding: 1.5rem 2rem;
    width: 100%; }
    .form_form__aJV9d input::-webkit-input-placeholder {
      font-weight: bold; }
    .form_form__aJV9d input::-moz-placeholder {
      font-weight: bold; }
    .form_form__aJV9d input:-ms-input-placeholder {
      font-weight: bold; }
    .form_form__aJV9d input::-ms-input-placeholder {
      font-weight: bold; }
    .form_form__aJV9d input::placeholder {
      font-weight: bold; }
    .form_form__aJV9d input:focus {
      outline: 0; }
  .form_form__aJV9d textarea {
    border: 1px solid #eeeeee;
    color: #666666;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    padding: 1.5rem 2rem;
    width: 100%;
    font-family: 'Mukta', sans-serif;
    resize: none;
    height: 200px; }
    .form_form__aJV9d textarea::-webkit-input-placeholder {
      font-weight: bold; }
    .form_form__aJV9d textarea::-moz-placeholder {
      font-weight: bold; }
    .form_form__aJV9d textarea:-ms-input-placeholder {
      font-weight: bold; }
    .form_form__aJV9d textarea::-ms-input-placeholder {
      font-weight: bold; }
    .form_form__aJV9d textarea::placeholder {
      font-weight: bold; }
    .form_form__aJV9d textarea:focus {
      outline: 0; }
  .form_form__aJV9d button {
    background: #f9bf26;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    line-height: 4.4rem;
    text-transform: uppercase;
    margin: 2.5rem 0 1.5rem;
    padding: 0 8.5rem 0 4.5rem;
    position: relative; }
    .form_form__aJV9d button::before {
      background: #2b2b2f;
      content: '\2192';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 4.4rem; }

.content_content__QzdKv {
  position: relative; }
  .content_content__QzdKv::before {
    background: #f8f8f8;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 300px;
    width: 65%; }
  @media (min-width: 1200px) {
    .content_content__QzdKv {
      width: 60%; } }
  .content_content__contact__3iaJF {
    border-bottom: 1px solid #f6f6f6;
    padding: 6rem 0 5rem 0;
    position: relative; }
    .content_content__contact__3iaJF h3 {
      color: #000000;
      font-size: 2.6rem;
      line-height: 3.4rem;
      letter-spacing: 0.2rem;
      padding-top: 2rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
      position: relative; }
      .content_content__contact__3iaJF h3::before {
        background: #f9bf26;
        content: '';
        position: absolute;
        top: -2rem;
        left: 0;
        height: 2px;
        width: 3rem; }
    .content_content__contact__3iaJF > p {
      color: #b6b6b6;
      font-size: 1rem;
      font-weight: bold;
      line-height: 2.2rem;
      letter-spacing: 0.1rem;
      max-width: 450px;
      padding: 1rem 0;
      text-transform: uppercase; }
  .content_content__getInTouch__3Aji0 {
    padding: 6rem 0 5rem 0;
    position: relative; }
    .content_content__getInTouch__3Aji0 h3 {
      color: #000000;
      font-size: 2.6rem;
      line-height: 3.4rem;
      letter-spacing: 0.2rem;
      padding-top: 2rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
      position: relative; }
      .content_content__getInTouch__3Aji0 h3::before {
        background: #f9bf26;
        content: '';
        position: absolute;
        top: -2rem;
        left: 0;
        height: 2px;
        width: 3rem; }
    .content_content__getInTouch__3Aji0 > p {
      color: #b6b6b6;
      font-size: 1rem;
      font-weight: bold;
      line-height: 2.2rem;
      letter-spacing: 0.1rem;
      max-width: 450px;
      padding: 1rem 0;
      text-transform: uppercase; }

@media (min-width: 1200px) {
  .contact-us-pg_contact__1VSAG {
    display: flex;
    margin-left: 90px; } }

