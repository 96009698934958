// Colors.
$shark: #2d2d32;
$lightning-yellow: #f9bf26;
$boulder: #767676;
$white: #ffffff;
$black: #000000;

// color config.
$primary-color: $shark;
$primary-color-dark: darken($primary-color, 1%);
$primary-color-darker: darken($primary-color, 3%);
$primary-color-light: lighten($primary-color, 3%);
$secondary-color: $lightning-yellow;
$font-color-light: lighten($boulder, 25%);
$font-color-dark: $boulder;
